import React from 'react';
import { Route } from 'react-router-dom';
import { LoginCallback, SecureRoute } from '@okta/okta-react';
import Assets from '../components/Assets';
import AllAssets from '../components/AllAssets';
import CreateAsset from '../components/CreateAsset';
import AssetTypes from '../components/AssetTypes';
import CreateAssetType from '../components/CreateAssetType';
import EditAssetType from '../components/EditAssetType';
import Customers from '../components/Customers';
import CustomerManagement from '../components/CustomerManagement';
import EditCustomerManagement from '../components/CustomerManagement/EditCustomer';
import CustomerDetailsManagement from '../components/CustomerManagement/CustomerDetails';
import CreateCustomerManagement from '../components/CustomerManagement/CreateCustomer';
import ImpactFunctions from '../components/ImpactFunctions';
import ImpactFunctionDetails from '../components/ImpactFunctionDetails';
import CreateImpactFunction from '../components/CreateImpactFunction';
import EditImpactFunction from '../components/EditImpactFunction';
import Hazards from '../components/Hazards';
import HazardDetails from '../components/HazardDetails';
import EditHazard from '../components/EditHazard';
import LandingPage from '../components/LandingPage';
import CustomerDetails from '../components/CustomerDetails';
import AssetDetails from '../components/AssetDetails';
import GroupDetails from '../components/GroupsDetails';
import EditAsset from '../components/EditAsset';
import * as constants from '../utils/constants';
import RealAssetsDashboard from '../components/RealAssetsDashboard';
import BulkImportFile from '../FileProcessing/components/UploadAssets';
import BulkImportTrack from '../FileProcessing';
import ExportPage from '../Export/ExportPage';
import MRPExportPage from '../MultipleReturnPeriodExport/MRPExportPage';
import SummaryReportPage from '../SummaryReport/SummaryReportPage';
import RbacSuperAdmin from '../components/RbacSuperAdmin';
import RbacClientAdmin from '../components/RbacClientAdmin';
import Methodology from '../components/Methodology';
import WhatsNew from '../WhatsNew';
import Consent from '../components/Consent/Consent';
import IF_Updates_022621 from '../components/UpdatesToImpactFunctions/Updates_0221/IF_Updates_022621';
import IF_Updates_032621 from '../components/UpdatesToImpactFunctions/Updates_0321/ImpactFunctions/IF_Updates_032621';
import IF_Updates_051821 from '../components/UpdatesToImpactFunctions/Updates_0521/ImpactFunctions/IF_Updates_051821';
import IF_Updates_081721 from '../components/UpdatesToImpactFunctions/Updates_0821/ImpactFunctions/IF_Updates_081721';
import IF_Updates_120321 from '../components/UpdatesToImpactFunctions/Updates_1221/ImpactFunctions/IF_Updates_120321';
import AT_Updates_031721 from '../components/UpdatesToImpactFunctions/Updates_0321/AssetTypes/AT_Updates_031721';
import AT_Updates_051821 from '../components/UpdatesToImpactFunctions/Updates_0521/AssetTypes/AT_Updates_051821';
import AT_Updates_081721 from '../components/UpdatesToImpactFunctions/Updates_0821/AssetTypes/AT_Updates_081721';
import AT_Updates_120321 from '../components/UpdatesToImpactFunctions/Updates_1221/AssetTypesNew/AT_Updates_120321';
import AT_Updates_120321_2 from '../components/UpdatesToImpactFunctions/Updates_1221/AssetTypesUpdate/AT_Updates_120321_2';
import AT_Updates_031822 from '../components/UpdatesToImpactFunctions/Updates_0322/AssetTypesNew/AT_Updates_031822';
import AT_Updates_031822_2 from '../components/UpdatesToImpactFunctions/Updates_0322/AssetTypesUpdate/AT_Updates_031822_2';
import AT_Updates_072722 from '../components/UpdatesToImpactFunctions/Updates_0722/AT_Updates_072722';
import './components.scss';

export default function Routes({ navMeta, handleFileUpload, cancelRequest }) {
    // Full URL
    const fullUrl = window.location.href;

    const isEUorCADomain = (url) =>
      url.toLowerCase().includes('eu.climanomics.spglobal.com') ||
      url.toLowerCase().includes('eu-stg.climanomics.spglobal.com') ||
      url.toLowerCase().includes('ca.climanomics.spglobal.com') ||
      url.toLowerCase().includes('ca-stg.climanomics.spglobal.com');

    return (
        <>
            <Route path="/login/callback" component={LoginCallback} />
            <SecureRoute path="/" exact={true} component={LandingPage} />

            {/* Real Assets */}
            <SecureRoute
                path="/real-assets"
                exact={true}
                render={(routeProps) => (
                    <Customers resourceType={constants.CUSTOMER_LIST_PORTFOLIO} {...routeProps} />
                )}
            />
            <SecureRoute
                path="/real-assets/:customerId"
                exact={true}
                render={(routeProps) => (
                    <RealAssetsDashboard entityType={constants.CUSTOMER_ENTITY} {...routeProps} />
                )}
            />
            <SecureRoute
                path="/real-assets/:customerId/views/:viewId"
                exact={true}
                render={(routeProps) => (
                    <RealAssetsDashboard entityType={constants.VIEW_ENTITY} {...routeProps} />
                )}
            />

            <SecureRoute
                path="/real-assets/:customerId/folders/:folderId"
                exact={true}
                render={(routeProps) => (
                    <RealAssetsDashboard entityType={constants.FOLDER_ENTITY} {...routeProps} />
                )}
            />

            <SecureRoute
                path="/real-assets/:customerId/views/:viewId/folders/:folderId"
                exact={true}
                render={(routeProps) => (
                    <RealAssetsDashboard entityType={constants.FOLDER_ENTITY} {...routeProps} />
                )}
            />
            <SecureRoute
                path="/real-assets/:customerId/assets/:assetId"
                exact={true}
                render={(routeProps) => (
                    <AssetDetails entityType={constants.ASSET_ENTITY} {...routeProps} />
                )}
            />
            <SecureRoute
                path="/real-assets/:customerId/folders/:folderId/assets/:assetId"
                exact={true}
                render={(routeProps) => (
                    <AssetDetails entityType={constants.ASSET_ENTITY} {...routeProps} />
                )}
            />
            <SecureRoute
                path="/real-assets/:customerId/views/:viewId/folders/:folderId/assets/:assetId"
                exact={true}
                render={(routeProps) => (
                    <AssetDetails entityType={constants.ASSET_ENTITY} {...routeProps} />
                )}
            />
            <SecureRoute
                path="/real-assets/:customerId/views/:viewId/folders/:folderId/assets/:assetId/edit"
                exact={true}
                render={(routeProps) => (
                    <EditAsset entityType={constants.ASSET_ENTITY} {...routeProps} />
                )}
            />

            {/* Real Assets - Manage */}
            <SecureRoute
                path="/manage"
                exact={true}
                render={(routeProps) => (
                    <Customers resourceType={constants.CUSTOMER_LIST_PORTFOLIO} {...routeProps} />
                )}
            />
            <SecureRoute path="/manageCustomer" exact={true} component={CustomerManagement} />
            <SecureRoute path="/createCustomer" exact={true} component={CreateCustomerManagement} />
            <SecureRoute path="/editCustomer/:id" exact={true} component={EditCustomerManagement} />
            <SecureRoute
                path="/customerDetails/:id"
                exact={true}
                component={CustomerDetailsManagement}
            />

            {/* <SecureRoute path="/manage/:customerId/groups" exact={true} component={Groups} /> */}

            <SecureRoute path="/manage/:customerId" exact={true} component={CustomerDetails} />
            <SecureRoute
                path="/manage/:customerId/views/:viewId"
                exact={true}
                component={GroupDetails}
            />
            <SecureRoute
                path="/manage/:customerId/folders/:folderId/assets"
                exact={true}
                component={Assets}
            />
            <SecureRoute
                path="/manage/:customerId/views/:viewId/folders/:folderId/assets"
                exact={true}
                component={Assets}
            />
            <SecureRoute
                path="/manage/:customerId/asset"
                exact={true}
                render={(routeProps) => (
                    <CreateAsset entityType={constants.ASSET_ENTITY} {...routeProps} />
                )}
            />
            <SecureRoute
                path="/manage/:customerId/folders/asset"
                exact={true}
                render={(routeProps) => (
                    <CreateAsset entityType={constants.ASSET_ENTITY} {...routeProps} />
                )}
            />
            <Route
                path="/manage/:customerId/folders/:folderId/asset"
                exact={true}
                render={(routeProps) => (
                    <CreateAsset entityType={constants.ASSET_ENTITY} {...routeProps} />
                )}
            />
            <SecureRoute
                path="/manage/:customerId/views/:viewId/folders/:folderId/asset"
                exact={true}
                render={(routeProps) => (
                    <CreateAsset entityType={constants.ASSET_ENTITY} {...routeProps} />
                )}
            />
            <SecureRoute
                path="/real-assets/:customerId/folders/:folderId/assets/:assetId/edit"
                exact={true}
                render={(routeProps) => (
                    <EditAsset entityType={constants.ASSET_ENTITY} {...routeProps} />
                )}
            />
            <SecureRoute
                path="/real-assets/:customerId/assets/:assetId/edit"
                exact={true}
                render={(routeProps) => (
                    <EditAsset entityType={constants.ASSET_ENTITY} {...routeProps} />
                )}
            />
            <SecureRoute
                path="/manage/:customerId/assets/:assetId/edit"
                exact={true}
                render={(routeProps) => (
                    <EditAsset entityType={constants.ASSET_ENTITY} {...routeProps} />
                )}
            />
            <SecureRoute
                path="/manage/:customerId/folders/:folderId/assets/:assetId/edit"
                exact={true}
                render={(routeProps) => (
                    <EditAsset entityType={constants.ASSET_ENTITY} {...routeProps} />
                )}
            />

            {/* Consent */}
            <SecureRoute path="/consent" exact={true} component={Consent} />

            {/* Asset Types */}
            <SecureRoute path="/asset-type" exact={true} component={CreateAssetType} />
            <SecureRoute path="/asset-types" exact={true} component={AssetTypes} />
            <SecureRoute
                path="/asset-types/:assetTypeId/edit"
                exact={true}
                component={EditAssetType}
            />
            {/* Impact Functions */}
            <SecureRoute path="/impact-function" exact={true} component={CreateImpactFunction} />
            <SecureRoute path="/impact-functions" exact={true} component={ImpactFunctions} />
            <SecureRoute
                path="/impact-functions/:id"
                exact={true}
                component={ImpactFunctionDetails}
            />
            <SecureRoute
                path="/impact-functions/:id/edit"
                exact={true}
                component={EditImpactFunction}
            />
            {/* Hazards */}
            <SecureRoute path="/hazards" exact={true} component={Hazards} />
            <SecureRoute path="/hazards/:hazardId" exact={true} component={HazardDetails} />
            <SecureRoute path="/hazards/:hazardId/edit" exact={true} component={EditHazard} />
            {/* Bulk Import */}
            <SecureRoute
                path="/bulk-import-file/:customerId"
                exact={true}
                render={(routeProps) => (
                    <BulkImportFile
                        handleFileUpload={handleFileUpload}
                        entityType={constants.CUSTOMER_ENTITY}
                        {...routeProps}
                    />
                )}
            />
            {/* Bulk Import */}
            <SecureRoute
                path="/bulk-import-track-file"
                exact={true}
                render={(routeProps) => (
                    <Customers resourceType={constants.CUSTOMER_LIST_DASHBOARD} {...routeProps} />
                )}
            />
            <SecureRoute
                path="/bulk-import-track-file/:customerId"
                exact={true}
                render={(routeProps) => (
                    <BulkImportTrack
                        handleFileUpload={handleFileUpload}
                        cancelRequest={cancelRequest}
                        entityType={constants.CUSTOMER_ENTITY}
                        {...routeProps}
                    />
                )}
            />
            {/* RBAC Super Admin */}
            <SecureRoute
                path="/rbac-super-admin"
                exact={true}
                render={(routeProps) => (
                    <RbacSuperAdmin entityType={constants.CUSTOMER_ENTITY} {...routeProps} />
                )}
            />
            {/* RBAC client Admin */}
            <Route
                path="/rbac-client-admin/:customerId"
                exact={true}
                render={(routeProps) => (
                    <RbacClientAdmin entityType={constants.CUSTOMER_ENTITY} {...routeProps} />
                )}
            />
            {/* RBAC client Admin */}
            <SecureRoute
                path="/rbac-client-admin"
                exact={true}
                render={(routeProps) => (
                    <Customers
                        resourceType={constants.CUSTOMER_LIST_PORTFOLIO}
                        route="/rbac-client-admin"
                        entityType={constants.CUSTOMER_ENTITY}
                        {...routeProps}
                    />
                )}
            />
            {/* Export */}
            <SecureRoute path="/export" exact={true} component={ExportPage} />
            {/* Multiple Return Period */}
            {!isEUorCADomain(fullUrl) && (
                <SecureRoute path="/multiple-return-period" exact={true}  component={MRPExportPage} />
            )}
            {/* Summary Report Builder */}
            <SecureRoute path="/summary-report" exact={true} component={SummaryReportPage} />
            {/* Other */}
            <SecureRoute path="/methodology/real-assets" exact={true} component={Methodology} />
            <SecureRoute path="/whatsnew" exact={true} component={WhatsNew} />
            <SecureRoute
                path="/impact-function-updates-022621"
                exact={true}
                component={IF_Updates_022621}
            />
            <SecureRoute
                path="/impact-function-updates-032621"
                exact={true}
                component={IF_Updates_032621}
            />
            <SecureRoute
                path="/impact-function-updates-051821"
                exact={true}
                component={IF_Updates_051821}
            />
            <SecureRoute
                path="/impact-function-updates-081721"
                exact={true}
                component={IF_Updates_081721}
            />
            <SecureRoute
                path="/impact-function-updates-120321"
                exact={true}
                component={IF_Updates_120321}
            />
            <SecureRoute
                path="/asset-type-updates-031721"
                exact={true}
                component={AT_Updates_031721}
            />
            <SecureRoute
                path="/asset-type-updates-051821"
                exact={true}
                component={AT_Updates_051821}
            />
            <SecureRoute
                path="/asset-type-updates-081721"
                exact={true}
                component={AT_Updates_081721}
            />
            <SecureRoute
                path="/asset-type-updates-120321"
                exact={true}
                component={AT_Updates_120321}
            />
            <SecureRoute
                path="/asset-type-updates-120321_2"
                exact={true}
                component={AT_Updates_120321_2}
            />
            <SecureRoute
                path="/asset-type-updates-031822"
                exact={true}
                component={AT_Updates_031822}
            />
            <SecureRoute
                path="/asset-type-updates-031822_2"
                exact={true}
                component={AT_Updates_031822_2}
            />
            <SecureRoute
                path="/asset-type-updates-072722"
                exact={true}
                component={AT_Updates_072722}
            />
        </>
    );
}
