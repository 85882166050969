import React, { useState, useEffect } from 'react';
import MRPExportTable from './MRPExportTable';
import MRPExportForm from './MRPExportForm';
import BreadcrumbTrail from '../components/BreadcrumbTrail';
import { TableDataContextProvider, ParagraphLg } from '../components/shared';
import { DEFAULT_PAGE_DATA } from '../components/components.constants';
import { addDynamicStyles } from '../utils/generateReportUtils';
import { getReports } from '../services/exportService';

export default function MRPExportPage() {
    const [pageData, setPageData] = useState(DEFAULT_PAGE_DATA);
    const [tableData, setTableData] = useState([]);
    const [count, setCount] = useState(0);
    const [selectedCustomer, setSelectedCustomer] = useState({});

    const getExportList = async (offset, limit, refreshData, searchData, sortData) => {
        let res = [];
        const reqData = {
            offset,
            limit,
            customerId: selectedCustomer.id,
            report_type: 'list_report',
        };
        if (searchData) reqData[`${searchData.searchtype}`] = searchData.searchText;
        if (sortData) {
            reqData.sort_field = sortData.sort_field;
            reqData.order_by = sortData.order_by;
        }
        if (refreshData) {
            res = refreshData;
            const result = addDynamicStyles(res);
            return result;
        }
        res = await getReports(reqData);
        const totalCount = res?.count;
        setCount(totalCount);
        const results = addDynamicStyles(res?.results);
        return results;
    };

    useEffect(() => {
        try {
            (async function retrieveData() {
                if (selectedCustomer?.id) {
                    const data = await getExportList(0, pageData.rowsPerPage);
                    setTableData(data);
                }
            })();
        } catch (e) {
            console.log('error occured in fetching export list: ', e);
        }
    }, [selectedCustomer]);

    const updateTableState = (data) => {
        // this we need to do because for a state with array, the component only re renders when we assign new array to the state,
        // instead of modifying the current array of the state.
        const updatedState = [...data];
        setTableData(updatedState);
    };

    return (
        <>
            <BreadcrumbTrail rootTitle="MAAL for Multiple Return Periods [BETA]" />
            <div className="spg-w-100 spg-p-md">
                <div className="spg-text spg-text-paragraph">
                   Climanomics calculates asset-level risk at different return periods for the Coastal Flooding hazard. Risk is calculated based on an absolute hazard baseline for a selection of real estate asset types. Please refer to the Technical Note for a deeper understanding of our methodology. 
                   
                   This feature is not automatically available for all portfolios. To export your assets’ risks for different return periods, please contact your Relationship Manager or Climanomics_Support@spglobal.com. 
                </div>
                <MRPExportForm
                    selectedCustomer={selectedCustomer}
                    setSelectedCustomer={setSelectedCustomer}
                    getExportList={getExportList}
                    updateTableState={updateTableState}
                    pageData={pageData}
                />
                {/* <TableDataContextProvider exportTableData={tableData}>
                    <MRPExportTable
                        selectedCustomer={selectedCustomer}
                        tableData={tableData}
                        getExportList={getExportList}
                        updateTableState={updateTableState}
                        count={count}
                        pageData={pageData}
                        setPageData={setPageData}
                    />
                </TableDataContextProvider> */}
            </div>
        </>
    );
}
