import React from 'react';
import { Button, Modal, ModalContent, ModalFooter } from '@spglobal/react-components';
import { Purpose } from '@spglobal/koi-helpers';
import { TextBodyLg } from '../components/shared';

export default function MRPExportLimitDialog(props) {
    const handleClose = () => {
        props.handleClose();
    };

    return (
        <div>
            <Modal isOpen={props.open} onClose={() => handleClose()}>
                <ModalContent className="spg-mt-lg">
                    <TextBodyLg>
                        The export you are trying to generate exceeds 10,000 asset records. Please
                        export by Folder by selecting specific Folders containing 10,000 asset
                        records or less in the folder dropdown. If you need more than 10,000 asset
                        records in one export, please contact your Relationship Manager.
                    </TextBodyLg>
                </ModalContent>
                <ModalFooter>
                    <Button purpose={Purpose.PRIMARY} onClick={() => handleClose()}>
                        Go Back to Export Builder
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}
