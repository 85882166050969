import CryptoJS from 'crypto-js';

const FetchString = (combined) => {
    const combinedWords = CryptoJS.enc.Base64.parse(combined);
    const key = CryptoJS.lib.WordArray.create(combinedWords.words.slice(0, 8)); // Extract the key
    const iv = CryptoJS.lib.WordArray.create(combinedWords.words.slice(8, 12)); // Extract the IV
    const ciphertext = CryptoJS.lib.WordArray.create(combinedWords.words.slice(12));
    const decrypted = CryptoJS.AES.decrypt({ ciphertext }, key, { iv });
    return decrypted.toString(CryptoJS.enc.Utf8);
  };

const CommonFunctions = {
    FetchString
};

export default CommonFunctions;
