import React from 'react';
import { Button, Icon, Modal, ModalContent, ModalFooter } from '@spglobal/react-components';
import { EXPORT } from '@spglobal/koi-icons';
import { Purpose } from '@spglobal/koi-helpers';
import { GenerateReportModalTable } from './GenerateReportModal.styles';

export default function GenerateReportModal({ tableOptions, onConfirm }) {
    return (
        <div>
            <Modal
                isOpen={true}
                onClose={() => onConfirm(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <ModalContent>
                    <GenerateReportModalTable aria-label="simple table">
                        <tbody>
                        {tableOptions.map((row, index) => (
                            <tr key={index}>
                                <td>{row.label}</td>
                                <td>{row.value}</td>
                            </tr>
                        ))}
                        </tbody>
                    </GenerateReportModalTable>
                </ModalContent>
                <ModalFooter className="spg-d-flex spg-justify-end">
                    <Button
                        onClick={() => onConfirm(true)}
                        leftIcon={<Icon icon={EXPORT} />}
                        purpose={Purpose.PRIMARY}
                    >
                        Download
                    </Button>
                    <Button
                        onClick={() => onConfirm(false)}
                        purpose={Purpose.SECONDARY}
                        className="spg-ml-sm"
                    >
                        CANCEL
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};
