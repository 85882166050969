import React from 'react';
import GenerateReportModal from '../components/GenerateReportModal';

export default function MRPExportSummaryModal(props) {
    const { selectedCustomer, selectedReturnPeriod, onConfirm } = props;

    const getExportDetails = () => [
        { label: 'Customer:', value: selectedCustomer?.label },

        { label: 'Selected Output:', value: selectedReturnPeriod?.label },
    ];

    return <GenerateReportModal tableOptions={getExportDetails()} onConfirm={onConfirm} />;
}
